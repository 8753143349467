<template>
  <div class="deal-with-facilities">
    <x-table
        :columns="table.columns"
        :config="config"
        :data="table.data"
        :loading="table.loading"
        :no-data-text="
        CA('terminalFacilities_check') ? '暂无数据' : '暂无数据查询权限'
      "
        @add="add"
        @loadEnter="loadEnter"
        @search="search"
        @page-change="pageChange"
        @page-size-change="pageSizeChange"
    ></x-table>

    <Modal
        v-model="modal.show"
        :title="modal.title"
        @on-visible-change="modalShow"
    >
      <Form ref="form" :label-width="140" :model="form" :rules="rules">
        <FormItem label="设施名称" prop="category">
          <Select v-model="form.category">
            <Option
                v-for="item in categoryList"
                :key="item.id"
                :value="item.id + '/' + item.name"
            >{{ item.name }}
            </Option
            >
          </Select>
        </FormItem>
        <FormItem label="所属加工厂" prop="breed">
          <Cascader
              v-model="form.breed"
              :data="farmScene"
              @on-change="breedChange"
          ></Cascader>
        </FormItem>
        <FormItem label="规模" prop="type">
          <RadioGroup v-model="form.type">
            <Radio label="1">
              <span>长度</span>
            </Radio>
            <Radio label="2">
              <span>容量</span>
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem v-if="form.type == '1'" label="长度" prop="length">
          <Input v-model="form.length">
            <span slot="append">m</span>
          </Input>
        </FormItem>
        <FormItem v-if="form.type == '2'" label="长" prop="long">
          <Input v-model="form.long">
            <span slot="append">m</span>
          </Input>
        </FormItem>
        <FormItem v-if="form.type == '2'" label="宽" prop="width">
          <Input v-model="form.width">
            <span slot="append">m</span>
          </Input>
        </FormItem>
        <FormItem v-if="form.type == '2'" label="高" prop="high">
          <Input v-model="form.high">
            <span slot="append">m</span>
          </Input>
        </FormItem>
        <FormItem label="单位" prop="unit">
          <Input v-model="form.unit"></Input>
        </FormItem>
        <FormItem
            v-if="
            form.category && form.category.split('/')[1].indexOf('管道') == -1
          "
            label="设施位置"
            prop="thirdLongLat"
        >
          <Input
              v-model="form.thirdLongLat"
              :placeholder="
              form.breed.length > 0
                ? '点击右侧图标选择设施位置'
                : '请先选择加工厂'
            "
              disabled
          >
            <span
                slot="append"
                :style="{
                cursor: form.breed.length > 0 ? 'pointer' : 'not-allowed',
              }"
                class="iconfont"
                @click="mapModalShow"
            >&#xe648;</span
            >
          </Input>
        </FormItem>
        <FormItem
            v-if="
            form.category && form.category.split('/')[1].indexOf('管道') > -1
          "
            label="管道绘制"
            prop="mapPosition"
        >
          <Input
              v-model="form.mapPosition"
              :placeholder="
              form.breed.length > 0 ? '点击右侧图标绘制管道' : '请先选择加工厂'
            "
              disabled
          >
            <span
                slot="append"
                :style="{
                cursor: form.breed.length > 0 ? 'pointer' : 'not-allowed',
              }"
                class="iconfont"
                @click="drawMapModalShow"
            >&#xe648;</span
            >
          </Input>
        </FormItem>
        <FormItem label="设施状态" prop="status">
          <Select v-model="form.status">
            <Option :value="0">闲置</Option>
            <Option :value="1">工作</Option>
            <Option :value="2">废弃</Option>
          </Select>
        </FormItem>
        <FormItem label="设施图纸" prop="drawings">
          <upload-image
              v-model="form.drawings"
              :clearFlag="!modal.show"
              :max="5"
          ></upload-image>
        </FormItem>
        <FormItem label="设施图片" prop="img">
          <upload-image
              v-model="form.img"
              :clearFlag="!modal.show"
              :max="5"
          ></upload-image>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button :loading="modal.submitLoading" type="primary" @click="submit"
        >提交
        </Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <Modal v-model="mapModel" fullscreen title="选择设施位置">
      <search-map
          v-if="mapModel"
          :backgroundPosition="backgroundPosition"
          :currentData="currentData"
          @back-location="dealMapData"
      ></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModel = false)">提交</Button>
        <Button @click="() => (mapModel = false)">取消</Button>
      </p>
    </Modal>

    <Modal v-model="drawMapModal" fullscreen title="绘制管道">
      <draw-map
          v-if="drawMapModal"
          ref="map"
          :backgroundPosition="backgroundPosition"
          :mapPosition="mapPosition2"
      ></draw-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="drawFinish">提交</Button>
        <Button @click="() => (drawMapModal = false)">取消</Button>
      </p>
    </Modal>

    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
</template>

<script>
import CU from "@/common/util";
import searchMap from "@/components/searchMap";
import drawMap from "@/components/drawMap";
import pictureView from "@/components/picture_view";

export default {
  name: "",
  components: {
    searchMap,
    drawMap,
    pictureView,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "设施名称",
            minWidth: 100,
            key: "categoryName",
          },
          {
            title: "所属基地",
            minWidth: 100,
            render: (h, {row}) => {
              return <span>{row.farmName}</span>;
            },
          },
          {
            title: "所属加工厂",
            minWidth: 100,
            render: (h, {row}) => {
              return <span>{row.sceneName}</span>;
            },
          },
          {
            title: "规模(长度，容量)",
            minWidth: 100,
            render: (h, {row}) => {
              if (this.isJSON(row.size)) {
                let size = JSON.parse(row.size);
                let type = size.type
                let value = size.value || {}

                if (value.toString() === "{}") return (<span></span>)
                return (
                    <span>
                  {type == "1"
                      ? value.length + "m"
                      : `${value.long}×${value.width}×${value.high}m³`}
                </span>
                );
              }
              return (<span></span>)
            },
          },
          {
            title: "设施图片",
            width: 135,
            render: (h, {row}) => {
              if (row.img) {
                return (
                    <div style="display:flex">
                      {row.img.split(",").map((item) => {
                        return (
                            <img
                                src={this.getImgUrl(row.servicePath, item)}
                                style="width: 35px;height: 35px;cursor:pointer;margin-right:10px"
                                onClick={() =>
                                    this.lookPicture(row.servicePath + item)
                                }
                            />
                        );
                      })}
                    </div>
                );
              } else {
                return <span>--</span>;
              }
            },
          },
          {
            title: "图纸",
            width: 135,
            render: (h, {row}) => {
              if (row.drawings) {
                return (
                    <div style="display:flex">
                      {row.drawings.split(",").map((item) => {
                        return (
                            <img
                                src={this.getImgUrl(row.servicePath, item)}
                                style="width: 35px;height: 35px;cursor:pointer;margin-right:10px"
                                onClick={() =>
                                    this.lookPicture(row.servicePath + item)
                                }
                            />
                        );
                      })}
                    </div>
                );
              } else {
                return <span>--</span>;
              }
            },
          },
          {
            title: "设施状态",
            width: 100,
            render: (h, {row}) => {
              return (
                  <span>
                  {row.status == 0 ? "闲置" : row.status == 1 ? "工作" : "废弃"}
                </span>
              );
            },
          },
          {
            title: "操作",
            width: 150,
            render: (h, {row}) => {
              return (
                  <div>
                    {this.CA("terminalFacilities_edit") && (
                        <a
                            style="margin-right: 10px"
                            onClick={() => this.edit(row)}
                        >
                          编辑
                        </a>
                    )}
                    {this.CA("terminalFacilities_del") && (
                        <Poptip
                            confirm
                            transfer
                            title="确定删除吗?"
                            on-on-ok={() => this.del(row.id)}
                        >
                          <a>删除</a>
                        </Poptip>
                    )}
                  </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        category: "",
        categoryId: "",
        categoryName: "",
        breed: [],
        farmId: "",
        sceneId: "",
        unit: "",
        thirdLongLat: "",
        mapPosition: "",
        drawings: "",
        img: "",
        siteType: "3",
        type: "1",
        length: "",
        long: "",
        width: "",
        high: "",
        status: "",
      },
      rules: {
        category: [{required: true, message: "请选择设施名称"}],
        breed: [{required: true, type: "array", message: "请选择所属加工厂"}],
        unit: [{required: true, message: "请填写单位"}],
        thirdLongLat: [{required: true, message: "请选择设施位置"}],
        mapPosition: [{required: true, message: "请绘制管道"}],
        length: [
          {required: true, message: "请填写长度"},
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "长度只能是数字",
          },
        ],
        long: [
          {required: true, message: "请填写长"},
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "长只能是数字",
          },
        ],
        width: [
          {required: true, message: "请填写宽"},
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "宽只能是数字",
          },
        ],
        high: [
          {required: true, message: "请填写高"},
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "高只能是数字",
          },
        ],
        status: [{required: true, message: "请选择设施状态"}],
      },
      farmScene: [],
      categoryList: [],

      mapModel: false,
      currentData: null,

      drawMapModal: false,
      mapPosition2: null,

      picture: {
        src: "",
        show: false,
      },

      backgroundPosition: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增设施",
            ca: "terminalFacilities_add",
          },
          width: 200,
          loadModel: {
            url: "https://castoff.hogdata.cn/app/设施.xlsx",
          },
          loadEnter: {
            loadName: "导入",
            ca: "terminalFacilities_import",
          },
          filterBox: [
            {
              conditionName: "设施名称",
              component: "select",
              field: "categoryId",
              defaultValue: "",
              data: this.categoryList,
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "所属加工厂",
              component: "select",
              field: "farmId",
              defaultValue: "",
              data: this.farmScene,
              parameterField: "value",
              showField: "label",
            },
            {
              conditionName: "施用状态",
              component: "select",
              field: "status",
              defaultValue: "",
              data: [
                {id: "0", name: "闲置"},
                {id: "1", name: "工作"},
                {id: "2", name: "废弃"},
              ],
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: {...this.page},
      };
      return config;
    },
  },
  methods: {
    //导入
    loadEnter() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        if (!input.files || input.length === 0) return;
        let formdata = new FormData();
        formdata.append("file", input.files[0]);
        formdata.append("userId", localStorage.getItem("userId"));
        formdata.append("companyNo", localStorage.getItem("companyNo"));
        formdata.append("siteType", 3);
        this.$post(this.$api.DEAL_FACILITIES.UPLOAD, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          this.$Message.success('导入成功')
          this.getList();
        });
      };
      input.click();
    },
    mapModalShow() {
      this.currentData = this.form.thirdLongLat;
      this.mapModel = true;
    },
    dealMapData(positionData) {
      this.form.thirdLongLat = positionData.thirdLongLat;
    },
    drawMapModalShow() {
      if (this.form.breed.length == 0) return;
      // let mapPosition = this.mapPosition;
      // mapPosition.features.forEach((item) => {
      //   item.properties.editArea = false;
      // });
      // if (this.form.mapPosition) {
      //   let n = JSON.parse(this.form.mapPosition);
      //   mapPosition.features = mapPosition.features.concat(n.features);
      // }
      this.mapPosition2 = this.form.mapPosition;
      this.drawMapModal = true;
    },
    drawFinish() {
      let n = this.$refs.map.getCurrentFeatures();
      this.form.mapPosition = JSON.stringify(n);
      this.drawMapModal = false;
    },
    add() {
      this.modal = {
        show: true,
        title: "新增设施",
        submitLoading: false,
      };
    },

    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },

    getList() {
      if (!this.CA("terminalFacilities_check")) return;
      this.table.loading = true;
      this.$post(this.$api.DEAL_FACILITIES.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "3",
        ...this.search_data,
      })
          .then((res) => {
            this.table.data = res.list;
            this.page.total = +res.total;
          })
          .finally(() => {
            this.table.loading = false;
          });
    },
    getBreedArea() {
      this.$post(this.$api.PRODUCTION_AREA.FARMSCENE, {
        siteType: "3",
      }).then((res) => {
        this.farmScene = CU.formatTreeData(res, "id", "name", "sceneList", [
          "mapPosition",
        ]);
      });
    },
    breedChange(value, selectedData) {
      let backgroundPosition = [];
      for (let i = 0; i < selectedData.length; i++) {
        let item = selectedData[i];
        item.mapPosition &&
        backgroundPosition.push(...JSON.parse(item.mapPosition));
      }
      this.backgroundPosition = backgroundPosition;
      this.form.farmId = value[0];
      this.form.sceneId = value[1];
    },
    getCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "24",
      }).then((res) => {
        this.categoryList = res.list;
      });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      let backgroundPosition = [];
      let farm = this.farmScene.find((item) => item.value == row.farmId);
      if (farm) {
        if (!!farm.mapPosition) {
          backgroundPosition.push(...JSON.parse(farm.mapPosition));
        }
        let scene = farm.children.find((it) => it.value == row.sceneId);
        if (scene && !!scene.mapPosition) {
          backgroundPosition.push(...JSON.parse(scene.mapPosition));
        }
      }
      this.backgroundPosition = backgroundPosition;
      this.form.category = row.categoryId + "/" + row.categoryName;
      this.form.breed = [row.farmId, row.sceneId];
      row.drawings &&
      (this.form.drawings = row.drawings
          .split(",")
          .map((item) => this.getImgUrl(row.servicePath, item))
          .join(","));
      row.img &&
      (this.form.img = row.img
          .split(",")
          .map((item) => this.getImgUrl(row.servicePath, item))
          .join(","));
      let size;
      if (this.isJSON(row.size)) {
        size = JSON.parse(row.size);
      } else {
        size = {
          type: '1',
          value: {
            length: 0,
          }
        }
      }

      this.form.type = size.type;
      if (size.type * 1 === 1) {
        this.form.length = size.value.length;
      } else {
        this.form.long = size.value.long;
        this.form.width = size.value.width;
        this.form.high = size.value.high;
      }
      // this.getMapPosition(this.form.breed);
      this.modal = {
        show: true,
        title: "编辑设施",
        submitLoading: false,
      };
    },

    isJSON(str) {
      if (typeof str == 'string') {
        try {
          const obj = JSON.parse(str);
          return !!(typeof obj == 'object' && obj);
        } catch (e) {
          return false;
        }
      }
      return false;
    },

    del(id) {
      this.$post(this.$api.DEAL_FACILITIES.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        category: "",
        categoryId: "",
        categoryName: "",
        breed: [],
        farmId: "",
        sceneId: "",
        unit: "",
        thirdLongLat: "",
        mapPosition: "",
        drawings: "",
        img: "",
        siteType: "3",
        type: "1",
        length: "",
        long: "",
        width: "",
        high: "",
        status: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        console.log(res);
        if (!res) return;
        let params = {...this.form};
        params.categoryId = this.form.category.split("/")[0];
        params.categoryName = this.form.category.split("/")[1];
        this.form.drawings &&
        (params.drawings = this.form.drawings
            .split(",")
            .map((item) => "image" + item.split("image")[1])
            .join(","));
        this.form.img &&
        (params.img = this.form.img
            .split(",")
            .map((item) => "image" + item.split("image")[1])
            .join(","));
        let obj1 = {
          length: this.form.length,
        };
        let obj2 = {
          long: this.form.long,
          width: this.form.width,
          high: this.form.high,
        };
        params.size = JSON.stringify({
          type: this.form.type,
          value: this.form.type == "1" ? {...obj1} : {...obj2},
        });
        params.type = "";
        params.length = "";
        params.long = "";
        params.width = "";
        params.high = "";
        delete params.category;
        delete params.breed;
        this.modal.submitLoading = true;
        this.$post(
            params.id
                ? this.$api.DEAL_FACILITIES.EDIT
                : this.$api.DEAL_FACILITIES.ADD,
            params
        )
            .then(() => {
              this.getList();
              this.modal.show = false;
            })
            .finally(() => {
              this.modal.submitLoading = false;
            });
      });
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      };
    },
  },
  mounted() {
    this.getList();
    this.getBreedArea();
    this.getCategory();
  },
};
</script>

<style lang="less" scoped>
.deal-with-facilities {
  width: 100%;
  height: 100%;
}
</style>
